import React from 'react'
import { graphql } from 'gatsby'
import ArticlePage from '../components/templates/article'


const Article = ({ pageContext, data }) => {
  const post = data.markdownRemark
  const posts = data.allMarkdownRemark.edges
  const tags = pageContext.tags
  const relatedIds = []
  tags.map(tag =>
    posts.map(({ node }) =>
    (node.frontmatter.tags && node.frontmatter.tags.length > 0 ? (node.frontmatter.tags.map(tag_ =>
        tag === tag_ &&
        relatedIds.indexOf(node.id) === -1 &&
        post.id !== node.id
          ? relatedIds.push(node.id)
          : null
      ) ) : null)
    )
  )

  const related = []
  relatedIds.map(id =>
    posts.map(({ node }) => (node.id === id ? related.push(node) : null))
  )

  const seriesChildren = []
  posts.map(({ node }) => (node.frontmatter.series == post.frontmatter.series ? seriesChildren.push(node) : null ))

  const parentChildren = []
  posts.map(({ node }) => (node.frontmatter.parent == pageContext.slug && post.frontmatter.title != node.frontmatter.title ? parentChildren.push(node) : null ))

  const postData = {
    title: post.frontmatter.title,
    thumbnail: post.frontmatter.image ? post.frontmatter.image && post.frontmatter.image.childImageSharp.resize.src : null,
    image: post.frontmatter.image ? post.frontmatter.image.childImageSharp.fluid : null,
    html: post.html,
    tags: tags,
    url: post.frontmatter.url, 
    related: related,
    parentChildren: parentChildren,
    series: post.frontmatter.series,
    seriesChildren: seriesChildren,
    date: post.frontmatter.date,
    time: post.timeToRead,
    slug: pageContext.slug,
    publish: post.frontmatter.publish,
    references: post.frontmatter.references,
    comments: true
  }

  return (
    <ArticlePage post={postData}/>
  )
}

export default Article

export const query = graphql`
  query ArticleQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      timeToRead
      frontmatter {
        title
        date
        tags
        url
        parent
        series
        references
      }
    }
    allMarkdownRemark {
      edges {
        node {
          id
          html
          timeToRead
          fields {
            slug
          }
          frontmatter {
            title
            date
            tags
            url
            parent
            series
            publish
          }
        }
      }
    }
  }
`
